import { addElement } from './utils.js'
import { style } from './styles.js'
import { texts } from './texts.js'
import { cpexCMP } from './cmp.js'

class cpexCmpSubscription {
  constructor () {
    this.version = '1.4.9'
    this.config = { enabled: true, ...(window.cpexCmpSubscriptionConfig || {}), ...(window.cpexCmpSubscriptionOverride || {}) }
    this.isConfigured = false
    this.modalVisible = false
  }

  async run () {
    // Open only when consent data is ready (vendors present) and partial (some denied purpose). Also check if consent is older than limit, if configured.
    if (this.config.enabled === true) {
      if (typeof this.config.isSubscribed !== 'function') { return console.error('cmpSubcription: Please define isSubscribed function in window.cpexCmpSubscriptionConfig') }
      try {
        const isSubscribed = await this.config.isSubscribed()
        if (isSubscribed !== true && ((this.isRequiredConsentMissing()) || this.isConsentOlderThanLimit())) {
          this.showModal()
        }
      } catch (err) { console.error('cmpSubscription: isSubscribed function failed', err) }
    }
  }

  isRequiredConsentMissing () {
    const consent = Didomi.getUserConsentStatusForAll()
    if (consent.vendors.enabled.length === 0) { return true }
    const required = this.config.required || ['cookies'] // required shouldn't be empty
    return required.some(val => consent.purposes.disabled.includes(val))
  }

  isConsentOlderThanLimit () {
    if (!this.config.consentDateLimit) { return false }
    const consentAge = new Date(Didomi.getUserStatus().updated)
    const limit = new Date(this.config.consentDateLimit)
    return consentAge < limit
  }

  configure () {
    const partnerNum = Didomi.getRequiredVendors().length
    const defaultTexts = texts({ partnerNum })
    // Settings
    const defaults = { generalCSS: style, texts: defaultTexts, veil: true, purposeList: true }
    const configTexts = (typeof this.config.texts === 'function') ? this.config.texts({ partnerNum }) : this.config.texts // get texts with params
    if (typeof configTexts === 'object') { this.config.texts = { ...defaults.texts, ...configTexts } } // overwrite defaults
    this.settings = { ...defaults, ...this.config }
    // Styles
    if (this.settings.generalCSS) { addElement('style', document.head, { innerHTML: this.settings.generalCSS() }) }
    if (this.settings.overrideCSS) { addElement('style', document.head, { innerHTML: this.settings.overrideCSS() }) }
    this.isConfigured = true    
  }

  onSubscribe () {
    // this.hideModal()
    window.open(this.settings.registerURL, '_self')
  }

  onLogin () {
    // this.hideModal()
  }

  showModal () {
    if (this.config.enabled !== true) { return }
    if (this.modalVisible) { return }
    this.modalVisible = true
    this.elements = {}
    if (!this.isConfigured) { this.configure() }
    if (this.settings.veil) { this.elements.veil = addElement('div', document.body, { id: 'cpexSubs_veil' }) }
    document.body.classList.add('subscriptionModalOpen')
    this.elements.wrapper = addElement('div', document.body, { id: 'cpexSubs_modalWrapper' })
    this.elements.modal = addElement('div', this.elements.wrapper, { id: 'cpexSubs_modal' })
    this.elements.content = addElement('div', this.elements.modal, { id: 'cpexSubs_content' })
    if (this.settings.primaryLogoURL) {
      this.elements.primaryLogo = addElement('img', this.elements.content, { id: 'cpexSubs_primaryLogo', src: this.settings.primaryLogoURL })
      if (Array.isArray(this.settings.primaryLogoSize)) {
        this.elements.primaryLogo.setAttribute('width', this.settings.primaryLogoSize[0])
        this.elements.primaryLogo.setAttribute('height', this.settings.primaryLogoSize[1])
      }
    }
    if (this.settings.secondaryLogoURL) {
      this.elements.secondaryLogo = addElement('img', this.elements.content, { id: 'cpexSubs_secondaryLogo', src: this.settings.secondaryLogoURL })
      if (Array.isArray(this.settings.secondaryLogoSize)) {
        this.elements.secondaryLogo.setAttribute('width', this.settings.secondaryLogoSize[0])
        this.elements.secondaryLogo.setAttribute('height', this.settings.secondaryLogoSize[1])
      }
    }
    if (this.settings.texts.mainHeadline) {
      addElement('div', this.elements.content, { innerHTML: this.settings.texts.mainHeadline, id: 'cpexSubs_headline' })
    }
    this.elements.main = addElement('main', this.elements.content)
    this.elements.cmp = addElement('div', this.elements.main, { id: 'cpexSubs_cmp' })
    this.elements.sub = addElement('div', this.elements.main, { id: 'cpexSubs_sub' })
    // Texts
    this.elements.cmpTop = addElement('div', this.elements.cmp, { id: 'cpexSubs_cmpText' })
    this.elements.subTop = addElement('div', this.elements.sub, { id: 'cpexSubs_subText' })
    addElement('h2', this.elements.cmpTop, { innerHTML: this.settings.texts.cmpHeadline })
    addElement('p', this.elements.cmpTop, { innerHTML: this.settings.texts.cmpBody })
    addElement('h2', this.elements.subTop, { innerHTML: this.settings.texts.subHeadline })
    addElement('p', this.elements.subTop, { innerHTML: this.settings.texts.subBody })

    // Buttons
    this.elements.cmpButtons = addElement('div', this.elements.cmp, { id: 'cpexSubs_cmpButtons' })
    this.elements.subButtons = addElement('div', this.elements.sub, { id: 'cpexSubs_subButtons' })

    // Consent
    this.elements.butConsent = addElement('button', this.elements.cmpButtons, { innerHTML: this.settings.texts.butConsent, id: 'cpexSubs_consentButton' })
    this.elements.linkPurposes = addElement('a', this.elements.cmpButtons, { innerHTML: this.settings.texts.linkPurposes, href: '#', id: 'cpexSubs_linkPurposes' })

    // Subscription
    this.elements.butRegister = addElement('button', this.elements.subButtons, { innerHTML: this.settings.texts.butRegister, id: 'cpexSubs_subscribeButton' })
    this.elements.butRegister.addEventListener('click', () => { this.onSubscribe() })
    this.elements.linkLogin = addElement('a', this.elements.subButtons, { href: this.settings.loginURL, innerHTML: this.settings.texts.linkLogin, id: 'cpexSubs_linkLogin' })
    this.elements.linkLogin.addEventListener('click', () => { this.onLogin() })

    // Bottom texts
    this.elements.bottom = addElement('div', this.elements.content, { id: 'cpexSubs_bottom' })

    if (this.settings.texts.bottomText) {
      addElement('p', this.elements.bottom, { innerHTML: this.settings.texts.bottomText, id: 'cpexSubs_bottomText' })
    }

    if (this.settings.purposeList) {
      const purposeList = Didomi.getCategories().map(category => {
        if (category.name?.cs) { return category.name.cs.toLowerCase() } // Returns name if it's a category
        const purposeId = category['purposeId']
        const purpose = Didomi.getPurposes()[purposeId]
        if (purpose && purpose.name && purpose.name.cs) { return purpose.name.cs.toLowerCase() } // Handles case where it's specific purpose
      })
      const hl = this.settings.texts.purposeListHeadline
      // Fallback is shown if purposeList is disabled/empty and purposeListHeadline is empty as well
      addElement('p', this.elements.bottom, { innerHTML: hl + purposeList.filter(Boolean).join(', ') || hl + this.settings.texts.purposeListFallback, id: 'cpexSubs_purposeList' })
    }

    // Links
    if (Array.isArray(this.settings.links) && this.settings.links.length > 0) {
      this.elements.links = addElement('p', this.elements.bottom, { id: 'cpexSubs_links' })
      this.settings.links.forEach(link => {
        if (link.href) {
          if (link.href === 'privacyPolicy') { link.href = Didomi.getConfig().app.privacyPolicyURL }
          addElement('a', this.elements.links, { ...link })
        }
      })
    }
    // Didomi functionality
    this.elements.butConsent.addEventListener('click', () => {
      this.hideModal()
      setTimeout(() => { Didomi.setUserAgreeToAll() }, 50) // defer to not affect Web Vitals: INP
    })
    this.elements.linkPurposes.addEventListener('click', () => {
      Didomi.preferences.show('purposes')
    })

    this.updateShadows = () => {
      const { content, modal } = this.elements
      const isTopVisible = content.scrollTop > 0
      const fromBottom = content.scrollHeight - content.clientHeight - content.scrollTop
      const isBottomVisible = fromBottom > 10
      modal.classList.toggle('hideTop', !isTopVisible)
      modal.classList.toggle('hideBottom', !isBottomVisible)
    }

    // Scroll indicator shadows
    this.elements.content.addEventListener('scroll', this.updateShadows)
    window.addEventListener('resize', this.updateShadows)
    this.updateShadows()

    // Closing of details
    const summaries = this.elements.cmp.querySelectorAll('details summary');
    summaries.forEach(summary => summary.addEventListener('click', (event) => {
      event.preventDefault() // handle with js instead
      const detail = summary.parentElement
      detail.hasAttribute('open') ? detail.removeAttribute('open') : detail.setAttribute('open', '')
      const details = Array.from(detail.parentElement.children)
      details.forEach(other => other !== detail && other.removeAttribute('open')) // close others
    }))
  }

  hideModal () {
    this.modalVisible = false
    if (this.elements.veil) { this.elements.veil.style.display = 'none' }
    this.elements.wrapper.style.display = 'none'
    document.body.classList.remove('subscriptionModalOpen')
  }
}

// Didomi config
window.didomiConfig = { notice: { enable: false } } // disable opening of notice
window.didomiEventListeners = window.didomiEventListeners || []
window.didomiEventListeners.push({ event: 'preferences.clickagreetoall', listener: () => { window.cpexCmpSubscription.hideModal() } })
window.didomiEventListeners.push({ event: 'preferences.clicksavechoices', listener: () => { if (window.cpexCmpSubscription.isRequiredConsentMissing() === false) { window.cpexCmpSubscription.hideModal() } } })
window.didomiEventListeners.push({ event: 'consent.changed',
  listener: async (context) => {
    let isSubscribed = false
    if (typeof window.cpexCmpSubscriptionConfig.isSubscribed === 'function') {
      isSubscribed = await window.cpexCmpSubscriptionConfig.isSubscribed().catch(err => console.error('cmpSubscription: isSubscribed function failed', err))
    }
    if (window.cpexCmpSubscription.isRequiredConsentMissing() && isSubscribed === false) { window.cpexCmpSubscription.showModal() }
  }
})

window.didomiOnReady = window.didomiOnReady || []
window.didomiOnReady.push(() => { // render once didomi is ready
  window.cpexCmpSubscription = new cpexCmpSubscription()
  window.cpexCmpSubscription.run()
})
window.cpexCmpTemplate = window.cpexCmpSubscriptionConfig.cmpTemplate

// ADD CMP
if (!window.Didomi && !document.getElementById('spcloader')) {
  window.cpexCMP = new cpexCMP(window.cpexCmpSubscriptionConfig)
}
